* {
  cursor: none !important;
}

a {
  color: inherit;
  transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

// a:hover {
//   color: #2141f9;
//   transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
// }

.App {
  padding: 6em 8em 6em 8em;
}

.custom-cursor {
  backdrop-filter: blur(1.5px) !important;
  -webkit-backdrop-filter: blur(1.5px) !important;
  /* background-color: var('--theme-ui-colors-primary'); */
  background-color: #80808033;
  border-radius: 2em;
  height: 1.5em;
  width: 1.5em;
  border: 1pt solid #fff;
}

.cursor--hidden {
  opacity: 0;
}

.navigation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:space-between;
}

.navigation-sub {
  display: flex;
  align-items: center;
}

.nav-item {
  padding: 1em;
  text-decoration: none;
  font-size: 14pt;
  font-weight: 600;
}

.logo {
  height: 4em;
  pointer-events: none;
}

.project-wrapper {
  margin: 4em -2em 4em 0;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  text-decoration: none;
}

.project-image-wrapper {
  width: 33%;
  text-decoration: none;
}

.project-image {
  position: relative;
  background-size: cover;
  background-position: 50% 75%;
  filter: saturate(1.5);
  border-radius: .5em;
  width: calc(100% - 1em);
  height: 25em;
  margin: 0 2em 1em 0;
  text-decoration: none;
  transition: 250ms cubic-bezier(0.23, 1, 0.320, 1);
}

.project-image:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  transition: 500ms cubic-bezier(0.23, 1, 0.320, 1);
}

.project-image:active {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  transition: 500ms cubic-bezier(0.23, 1, 0.320, 1);
}

.text-hover-wrapper {
  display: flex;
  position: absolute;
  color: #fff;
  background-color: rgba(176, 176, 176, .15);
  -webkit-backdrop-filter: blur(25px) saturate(.9) contrast(1.25) brightness(.9);
  backdrop-filter: blur(25px) saturate(.9) contrast(1.25) brightness(.9);
  border-radius: .5em;
  width: 100%;
  height: 25em;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 500ms cubic-bezier(0.23, 1, 0.320, 1);
}

.text-hover-wrapper:hover {
  opacity: 1;
  transition: 500ms cubic-bezier(0.23, 1, 0.320, 1);
}

.text-hover {
  display: block;
  font-size: 14pt;
  justify-content: center;
}

.project-text-mobile {
  display: none;
}

.tag {
  margin: 0;
  font-weight: 400;
}

.work-wrapper {
  margin: 4em 0;
  display: block;
  width: 100%;
}

.work-header {
  display: flex;
  justify-content: space-between;
}

.work-header-text {
  margin-right: 2em;
  word-wrap: break-word;
  width: 25vw;
}

.work-banner-image {
  min-width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: .5em;
  height: 35em;
  object-fit: contain;
}

.work-header-image {
  min-width: 70%;
  background-size: cover;
  background-position: 50% 75%;
  border-radius: .5em;
  height: 50em;
  object-fit: cover;
}

.work-body-image {
  min-width: 70%;
  background-size: cover;
  background-position: center;
  border-radius: .5em;
  height: 50em;
  object-fit: cover;
  margin: 2em 0 0;
  float: right;
}

.work-image-tile {
  min-width: calc(50% - 2em) !important;
  margin-right: 2em;
  height: 40em;
}

#apex {
  background-position: 50% 85%;
}

#post-human {
  background-position: 100% 0%;
  background-color: #222;
}

.back-link {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.about-wrapper {
  margin-top: 25vh;
}

.about-description {
  width: 50%
}

.social-link {
  width: 3em;
  height: 3em;
  border-radius: 2em;
}

@media only screen and (max-width: 87em) {
  .project-wrapper {
    margin: 4em 0;
  }

  .project-image-wrapper {
    width: 50%;
  }

  .project-image {
    height: 20em;
  }

  .text-hover-wrapper {
    height: 20em;
  }

  .work-header-image {
    height: 30em;
  }

  .work-body-image{
    height: 30em;
  }

  .about-description {
    width: 75%
  }
}


@media only screen and (max-width: 56em) {
  .App {
    padding: 4em 1.5em;
    overflow-x: hidden;
  }

  .nav-item {
    padding-inline-start: 1em;
  }

  .project-image-wrapper {
    width: 100%;
  }

  .project-image .text-hover-wrapper {
    height: 20em;
    width: 100%;
  }

  .work-header {
    display: block;
  }

  .work-header-text {
    margin-right: 0;
    width: 100%;
  }

  .work-header-image {
    margin-top: 2em;
    width: 100%;
    height: 20em;
  }

  .work-body-image {
    min-width: 100%;
    height: 20em;
  }

  .work-image-tile {
    min-width: calc(100% - 2em) !important;
    margin-right: 2em;
    height: 20em;
  }

  .about-description {
    width: 100%
  }
}

@media (hover: none) {
  .project-text-mobile {
    display: flex;
    margin: -.5em 0 2em 0;
  }

  .text-hover-wrapper {
    display: none;
  }
}
